// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/arrow-right-white.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".buy_status_tips_container{box-shadow:0 1px 3px 0 rgba(0,1,0,.1);background-color:#fffbe9}.buy_status_tips_container .tips_box{border-bottom:1px dashed #ddd;padding:10px 0;cursor:pointer;margin:0 10px}.buy_status_tips_container .tips_box:last-child{border-bottom:none;margin-bottom:10px}.buy_status_tips_container .tips_box .title{font-weight:700;font-size:14px;padding-bottom:10px}.buy_status_tips_container .tips_box .buy_status_box{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}.buy_status_tips_container .tips_box .buy_status_box .left_buy_tips{font-size:12px}.buy_status_tips_container .tips_box .buy_status_box .left_buy_tips .remainder_person span{color:#fb636f}.buy_status_tips_container .tips_box .buy_status_box .right_invite_entrance{color:#fff;font-size:12px;min-width:90px;height:30px;line-height:30px;padding-right:15px;text-align:center;box-shadow:0 2px 6px 0 rgba(106,211,59,.5);border-radius:15px;white-space:nowrap;background:#49c114 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right 15px center/12px}", ""]);
// Exports
module.exports = exports;
