// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/privacy_dialog_back_1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_privacy_dialog{margin-top:-20px}.comp_privacy_dialog .privacy_des{margin-top:10px;font-size:14px;font-family:PingFang SC;font-weight:400;line-height:20px;color:#333;text-align:left;counter-increment:li;margin-left:4px}.comp_privacy_dialog .privacy_des:before{content:\"\";margin-right:2px;font-size:16px}.comp_privacy_dialog .privacy_des a{color:#ffc000}.comp_privacy_dialog .privacy_des:first-child{margin-top:20px}.xinyaui-dialog{overflow:inherit;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;border-radius:15px}.xinyaui-dialog:before{content:\"\";position:absolute;left:50%;top:-80px;width:246px;height:96px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0/100% 100% no-repeat;-webkit-transform:translate3d(-50%,-1px,0);transform:translate3d(-50%,-1px,0)}.xinyaui-dialog .xinyaui-dialog__ft{border-radius:0 0 15px 15px;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;overflow:hidden}.xinyaui-dialog .xinyaui-dialog__ft:after{content:\"\";display:none}.xinyaui-dialog .xinyaui-dialog__ft .xinyaui-dialog__btn{width:100%;font-size:14px;font-family:PingFang SC;font-weight:400;line-height:20px;color:#999;margin-bottom:20px}.xinyaui-dialog .xinyaui-dialog__ft .xinyaui-dialog__btn:after{content:\"\";display:none}.xinyaui-dialog .xinyaui-dialog__ft .xinyaui-dialog__btn:first-child{width:180px;height:44px;background:#49c114;opacity:1;border-radius:23px;font-size:16px;text-align:center;line-height:44px;color:#fff}.xinyaui-dialog__bd:first-child{padding:2.9em 20px 2em}", ""]);
// Exports
module.exports = exports;
