// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_success.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/icon_fail.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".modal_mask{position:absolute;top:0;left:0;width:100%;height:100%;z-index:9999}.modal_mask .modal_text{z-index:10000000;position:fixed;min-width:7.6em;height:4em;line-height:4em;top:220px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);background:rgba(17,17,17,.7);text-align:center;border-radius:5px;color:#fff;padding:0 10px}.modal_mask .modal_text.center{top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.modal_mask .modal{position:absolute;top:0;left:0;width:100%;height:100%}.modal_mask .modal .success{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.modal_mask .modal .error{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.modal_mask .modal .icon_status{position:absolute;top:35%;left:50%;margin-left:-104.5px;width:205px;height:60px;background-position:50%;background-repeat:no-repeat;background-size:32.5px 32.5px;border-radius:5px 5px 0 0;background-color:rgba(0,0,0,.75)}.modal_mask .modal .icon_status .modal_text{z-index:10000;position:absolute;top:100%;width:205px;font-size:12px;text-align:center;color:#fff;border-radius:0 0 5px 5px;background-color:rgba(0,0,0,.75);padding-bottom:10px}", ""]);
// Exports
module.exports = exports;
