/* istanbul ignore file */

import Turbolinks from "turbolinks";
import UserAgent from "@/components/utils/UserAgent";
import { getLocalStorage } from "@/components/utils/helpers.js";
import $ from "jquery";
window.$ = window.jQuery = $;
var NativeBridge = {};
var XinYaNativeBridge = NativeBridge;
require("jquery-ujs");

if (UserAgent.isNativeClient()) {
  Turbolinks.start();

  window.XinYaNativeBridge = NativeBridge;

  var clickBubbled = function (event) {
    var processed = false;
    if (this.enabled && this.clickEventIsSignificant(event)) {
      var link = this.getVisitableLinkForNode(event.target);
      if (link) {
        var location = this.getVisitableLocationForLink(link);
        if (
          location &&
          this.applicationAllowsFollowingLinkToLocation(link, location)
        ) {
          processed = true;
          event.preventDefault();
          var action = this.getActionForLink(link);
          this.visit(location, { action: action });
        }
      }
    }

    if (!processed) {
      XinYaNativeBridge.clickBubbled(event);
    }
  };

  if (Turbolinks.controller) {
    Turbolinks.controller.clickBubbled = clickBubbled.bind(
      Turbolinks.controller
    );
    Turbolinks.Controller.prototype.clickBubbled = clickBubbled;
  }

  // Check if a function exists on native platform side.
  NativeBridge.has = function (method_name) {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[method_name]
    ) {
      return true;
    } else if (window.JSBridge && window.JSBridge[method_name]) {
      return true;
    }
    return false;
  };

  // Check if the version of the native bridge is greater than or equal to the target version.
  NativeBridge.isVersionGE = function (version, callback) {
    if (NativeBridge.has('getVersion')) {
      NativeBridge.getVersion(function(v) {
        callback(parseInt(v) >= parseInt(version));
      });
    } else {
      callback(false);
    }
  };

  NativeBridge.getVersion = function (callback) {
    NativeBridge._getVersionCallback = callback;
    NativeBridge._call("getVersion");
  };

  NativeBridge.afterGetVersion = function (version) {
    if (NativeBridge._getVersionCallback) {
      NativeBridge._getVersionCallback(version);
      NativeBridge._getVersionCallback = null;
    }
  };

  NativeBridge.request = function(params) {
    return new Promise((resolve, reject) => {
      if (!NativeBridge._requests) {
        NativeBridge._requests = {};
      }
      let requestId = new Date().getTime().toString();
      NativeBridge._requests[requestId] = { resolve, reject };
      params.requestId = requestId;
      NativeBridge._call("request", params);
    });
  };

  NativeBridge.afterRequest = function(requestId, res) {
    let { resolve, reject } = NativeBridge._requests[requestId];
    if (res.error) {
      reject(res);
    } else {
      resolve(res);
    }
    delete NativeBridge._requests[requestId]
  };

  NativeBridge._call = function (method_name, params) {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[method_name]
    ) {
      window.webkit.messageHandlers[method_name].postMessage(params);
    } else if (window.JSBridge && window.JSBridge[method_name]) {
      if (params) {
        window.JSBridge[method_name](JSON.stringify(params));
      } else {
        window.JSBridge[method_name]();
      }
    }
  };

  NativeBridge.signIn = function (element) {
    NativeBridge._signInParams = {
      element: element,
    };
    NativeBridge._call("signIn");
  };

  /**
   *
   * already_signed_in: true/false
   */
  NativeBridge.afterSignIn = function (already_signed_in) {
    const signInParams = NativeBridge._signInParams;
    if (signInParams.element) {
      if (signInParams.element instanceof HTMLAnchorElement) {
        window.location.href = $(signInParams.element).attr("data-href");
      } else if (signInParams.element instanceof Function) {
        signInParams.element(already_signed_in);
      }
    }
  };

  NativeBridge.wechatSignIn = function (element) {
    NativeBridge._signInParams = {
      element: element,
    };
    NativeBridge._call("wechatSignIn");
  };

  /**
   *  params:
   *    url
   *
   *  callback: function(success) {}
   */
  NativeBridge.wxpay = function (params, callback) {
    NativeBridge._wxpayCallback = callback;
    NativeBridge._call("wxpay", params);
  };

  /**
   *  success: boolean, true / false
   */
  NativeBridge.afterWxpay = function (success) {
    if (NativeBridge._wxpayCallback) {
      NativeBridge._wxpayCallback(success);
    }
  };

  /**
   *  params:
   *    url
   *    title
   *    description
   *
   *  callback: function(success) {}
   */
  NativeBridge.share = function (params, callback) {
    NativeBridge._shareCallback = callback;
    NativeBridge._call("share", params);
  };

  /**
   * success: boolean, true / false
   */
  NativeBridge.afterShare = function (success) {
    if (NativeBridge._shareCallback) {
      NativeBridge._shareCallback(success);
    }
  };

  NativeBridge.checkBaby = function (element) {
    NativeBridge._checkBabyParams = {
      element: element,
    };
    NativeBridge._call("checkBaby");
  };

  NativeBridge.afterCheckingBaby = function () {
    var checkBabyParams = NativeBridge._checkBabyParams;
    if (checkBabyParams.element) {
      if (checkBabyParams.element instanceof HTMLAnchorElement) {
        window.location.href = $(checkBabyParams.element).attr("data-href");
      } else if (checkBabyParams.element instanceof Function) {
        checkBabyParams.element();
      }
    }
  };

  NativeBridge.getAudioRecorderStatus = function (callback) {
    NativeBridge._getAudioRecorderStatusCallback = callback;
    NativeBridge._call("getAudioRecorderStatus");
  };

  /*
   * Status includes:
   *   recording
   *   stopped
   */
  NativeBridge.afterGetAudioRecorderStatus = function (status) {
    if (NativeBridge._getAudioRecorderStatusCallback) {
      NativeBridge._getAudioRecorderStatusCallback(status);
      NativeBridge._getAudioRecorderStatusCallback = null;
    }
  };

  NativeBridge.startRecord = function (params, callback) {
    NativeBridge._startRecordCallback = callback;
    NativeBridge._call("startRecord", params);
  };

  NativeBridge.afterStartRecord = function () {
    if (NativeBridge._startRecordCallback) {
      NativeBridge._startRecordCallback();
      NativeBridge._startRecordCallback = null;
    }
  };

  NativeBridge.stopRecord = function (callback) {
    NativeBridge._stopRecordCallback = callback;
    NativeBridge._call("stopRecord");
  };

  NativeBridge.afterStopRecord = function () {
    if (NativeBridge._stopRecordCallback) {
      NativeBridge._stopRecordCallback();
      NativeBridge._stopRecordCallback = null;
    }
  };

  NativeBridge.playVoice = function (callback) {
    NativeBridge._playVoiceCallback = callback;
    NativeBridge._call("playVoice");
  };

  NativeBridge.afterPlayVoice = function () {
    if (NativeBridge._playVoiceCallback) {
      NativeBridge._playVoiceCallback();
      NativeBridge._playVoiceCallback = null;
    }
  };

  NativeBridge.reStartRecord = function (callback) {
    NativeBridge._reStartCallback = callback;
    NativeBridge._call("reStartRecord");
  };

  NativeBridge.afterReStartRecord = function () {
    if (NativeBridge._reStartCallback) {
      NativeBridge._reStartCallback();
      NativeBridge._reStartCallback = null;
    }
  };

  NativeBridge.completeRecord = function (params, callback) {
    NativeBridge._completeRecordCallback = callback;
    NativeBridge._call("completeRecord", params);
  };

  NativeBridge.afterCompleteRecord = function () {
    if (NativeBridge._completeRecordCallback) {
      NativeBridge._completeRecordCallback();
      NativeBridge._completeRecordCallback = null;
    }
  };

  NativeBridge.getLastAudioData = function (callback) {
    NativeBridge._getLastAudioDataCallback = callback;
    NativeBridge._call("getLastAudioData");
  };

  NativeBridge.afterGetLastAudioData = function (data) {
    if (NativeBridge._getLastAudioDataCallback) {
      NativeBridge._getLastAudioDataCallback(data);
      NativeBridge._getLastAudioDataCallback = null;
    }
  };

  NativeBridge.playBgm = function (params) {
    NativeBridge._call("playBgm", params);
  };

  NativeBridge.pauseBgm = function () {
    NativeBridge._call("pauseBgm");
  };

  NativeBridge.keepScreenOn = function (params) {
    NativeBridge._call("keepScreenOn", params);
  };

  NativeBridge.keepScreenOff = function () {
    NativeBridge._call("keepScreenOff");
  };

  NativeBridge.checkPermission = function (callback) {
    NativeBridge._checkPermissionCallback = callback;
    NativeBridge._call("checkPermission");
  };

  NativeBridge.afterCheckPermission = function () {
    if (NativeBridge._checkPermissionCallback) {
      NativeBridge._checkPermissionCallback();
    }
  };

  NativeBridge.requestPermission = function (permission, callback) {
    NativeBridge._requestPermissionCallback = callback;
    NativeBridge._call("requestPermission", { permission });
  };

  NativeBridge.afterRequestPermission = function (granted) {
    if (NativeBridge._requestPermissionCallback) {
      NativeBridge._requestPermissionCallback(granted);
    }
  };

  /**
   * Current supported events include:
   *   appEnterBackground
   *   pageShowAgain
   *   pageHide
   */
  NativeBridge.registerEventListener = function (e, listener) {
    var listeners = NativeBridge._eventListeners;
    if (!listeners) {
      listeners = {};
      NativeBridge._eventListeners = listeners;
    }
    var l = listeners[e];
    if (!l) {
      l = [];
      listeners[e] = l
    }
    l.push(listener);
  };

  NativeBridge.unregisterEventListener = function (e, listener) {
    if (NativeBridge._eventListeners && NativeBridge._eventListeners[e]) {
      var l = NativeBridge._eventListeners[e];
      var idx = l.indexOf(listener);
      if (idx > -1) {
        l.splice(idx, 1);
      }
    }
  };

  NativeBridge.notifyEvent = function (e, params) {
    if (NativeBridge._eventListeners && NativeBridge._eventListeners[e]) {
      NativeBridge._eventListeners[e].forEach(l => l(params));
    }
  };

  NativeBridge.reload = function () {
    NativeBridge._call("reload");
  };

  NativeBridge.requestAuthorization = function (params, callback) {
    NativeBridge._requestAuthorizationCallback = callback;
    NativeBridge._call("requestAuthorization", params);
  };

  NativeBridge.afterRequestAuthorization = function (authState) {
    if (NativeBridge._requestAuthorizationCallback) {
      NativeBridge._requestAuthorizationCallback(authState);
    }
  };

  NativeBridge.chainingCall = function (element) {
    var args = Array.prototype.slice.call(arguments, 1);
    var i = 0;
    var len = args.length;
    var next = function () {
      if (i < len) {
        NativeBridge[args[i]](next);
        i++;
      } else {
        if (element instanceof HTMLAnchorElement) {
          window.location.href = $(element).attr("data-href");
        } else if (element instanceof Function) {
          element();
        }
      }
    };
    next();
  };

  NativeBridge.closeWindow = function () {
    NativeBridge._call("closeWindow");
  };

  /**
   *  params:
   *    msg
   *    callback
   */
  NativeBridge.showAlert = function (params) {
    NativeBridge._showAlertParams = params;
    NativeBridge._call("showAlert", {
      msg: params.msg,
    });
  };

  NativeBridge.afterShowAlert = function () {
    var showAlertParams = NativeBridge._showAlertParams;
    if (
      showAlertParams.callback &&
      showAlertParams.callback instanceof Function
    ) {
      showAlertParams.callback();
    }
  };

  NativeBridge.setProgressDialogVisible = function (visible) {
    NativeBridge._call("setProgressDialogVisible", {
      visible: visible,
    });
  };

  /**
   * params:
   *   target_type: school / course_category
   *   target_id
   */
  NativeBridge.startExamination = function (params) {
    NativeBridge._call("startExamination", params);
  };

  NativeBridge.showSchoolRoutes = function (school_id) {
    NativeBridge._call("showSchoolRoutes", {
      school_id: school_id,
    });
  };

  /**
   * params:
   *   feedback_type
   *   note
   */
  NativeBridge.createFeedback = function (params) {
    NativeBridge._call("createFeedback", params);
  };

  /**
   * params
   *   target_type: course_category
   *   target_id
   */
  NativeBridge.showCourseTree = function (params) {
    NativeBridge._call("showCourseTree", params);
  };

  /**
   * params
   *   href: url
   *   target: _blank / _self
   *   type: root
   */
  NativeBridge.navigate = function (params) {
    NativeBridge._call("navigate", params);
  };

  /**
   * Exit the application.
   *
   *  params:
   *    status: exit status
   */
  NativeBridge.exitApp = function (status) {
    NativeBridge._call("exit", { status });
  };

  /**
   * params
   *   name: examination_finished
   */
  NativeBridge.onPrivacyAccepted = function () {
    NativeBridge._call("onPrivacyAccepted");
  };

  NativeBridge.isPrivacyAccepted = function () {
    return !!(window.user_id || getLocalStorage("privacyAccepted") === 'true');
  };

  /**
   * params
   *   name: examination_finished
   */
  NativeBridge.onEvent = function (params) {
    NativeBridge._call("onEvent", params);
  };

  NativeBridge.match = function (element, selector) {
    var node = element;
    if (node.matches) {
      return node.matches;
    } else if (node.matchesSelector) {
      return node.matchesSelector;
    } else if (node.webkitMatchesSelector) {
      return node.webkitMatchesSelector;
    } else if (node.msMatchesSelector) {
      return node.msMatchesSelector;
    } else {
      return node.mozMatchesSelector;
    }
  };

  NativeBridge.closest = function (element, selector) {
    var a = element.closest && element.closest(selector);
    if (a) {
      return a;
    } else {
      var node = element;
      var result = false;
      while (node) {
        if (node.matchesSelector) {
          result = node.matchesSelector(selector);
        } else if (node.matches) {
          result = node.matches(selector);
        } else if (node.webkitMatchesSelector) {
          result = node.webkitMatchesSelector(selector);
        } else if (node.msMatchesSelector) {
          result = node.msMatchesSelector(selector);
        } else if (node.mozMatchesSelector) {
          result = node.mozMatchesSelector(selector);
        }
        if (result) {
          return node;
        }
        node = node.parentNode;
      }
    }
  };

  NativeBridge.clickBubbled = function (event) {
    if (
      (window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit &&
        window.webkit.messageHandlers.navigate) ||
      (window.JSBridge && window.JSBridge.navigate)
    ) {
      const element = NativeBridge.closest(event.target, "a[href]");
      if (element) {
        const $element = $(element);
        const href = $element.attr("href").toLowerCase();
        const target = $element.attr("target");
        if (href.length > 0 && !href.startsWith("javascript:")) {
          NativeBridge._call("navigate", {
            href: href,
            target: target,
          });
          event.preventDefault();
        }
      }
    }
  };
}

export default XinYaNativeBridge;
