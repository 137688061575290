import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Mask from "../mask/index";

const Dialog = (props) => {
  const {
    title,
    show,
    className,
    children,
    buttons,
    type,
    autoDectect,
    maskClassName,
    ...others
  } = props;
  const styleType = type || "ios";
  const cls = classNames("xinyaui-dialog", {
    "xinyaui-skin_android": styleType === "android",
    [className]: className,
  });

  const renderButtons = () => {
    return buttons.map((action, idx) => {
      const { type, label, ...others } = action;
      const className = classNames({
        "xinyaui-dialog__btn": true,
        "xinyaui-dialog__btn_default": type === "default",
        "xinyaui-dialog__btn_primary": type === "primary",
      });

      return (
        <a
          key={idx}
          href="javascript:void(0);"
          {...others}
          className={className}
        >
          {label}
        </a>
      );
    });
  };

  return show ? (
    <div>
      <Mask className={maskClassName} />
      <div className={cls} {...others}>
        {title ? (
          <div className="xinyaui-dialog__hd">
            <strong className="xinyaui-dialog__title">{title}</strong>
          </div>
        ) : (
          false
        )}
        <div className="xinyaui-dialog__bd">{children}</div>
        <div className="xinyaui-dialog__ft">{renderButtons()}</div>
      </div>
    </div>
  ) : (
    ""
  );
};

Dialog.propTypes = {
  buttons: PropTypes.array,
  show: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
};
Dialog.defaultProps = {
  buttons: [],
  show: false,
  title: "",
  type: "",
};

export default memo(Dialog);
