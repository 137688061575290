import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Mask from "../mask/index";
import Icon from "../icon/index";

const Toast = (props) => {
  const { className, icon, show, children, iconSize, ...others } = props;
  const cls = classNames("xinyaui-toast", {
    [className]: className,
  });
  return show ? (
    <div>
      <Mask transparent={true} />
      <div className={cls} {...others}>
        <Icon value={icon} size={iconSize} className="xinyaui-icon_toast" />
        <p className="xinyaui-toast_content" style={props.textstyle}>{children}</p>
      </div>
    </div>
  ) : (
    ""
  );
};

Toast.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  show: PropTypes.bool,
};
Toast.defaultProps = {
  icon: "toast",
  show: false,
};

export default memo(Toast);
