/* eslint-disable no-global-assign */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import React, { memo, useState, useCallback } from "react";
import Raven from "raven-js";
import queryString from "query-string";
import { formatDotDate } from "../../utils/formatAudioTime";
import Image from "../../utils/Image";
import iconSeries from "new_course_home/icon_series.png";
import qrcode from "media_lesson_activity/app_download.png";
import {
  request as axios,
  getAxiosErrorText,
  csrfHeaders,
} from "../../utils/request";

import { useSelector } from "../../utils/react-global-state";

import { ToastManager } from "../../utils/Toast";
import { awaitWrapper, showDialog } from "../../utils/helpers";
import "./OwnedCourses.scss";

const request = axios.create({
  timeout: 18000,
});

const UPDATE_VERSION_ACTIVITIES = [933, 934, 935, 936, 937, 938, 939, 940, 941];
const ActivityDescription = ({ ...props }) => {
  const {
    id,
    is_experience,
    description,
    start_time,
    phase,
    end_time,
    is_parent_class,
    is_interview,
    status,
    activity_type,
  } = props;
  if (is_experience) {
    return <p className="description">{description}</p>;
  } else {
    if (activity_type == 2) {
      return "";
    }
    return is_parent_class || is_interview ? (
      <p className="description">{description}</p>
    ) : phase && start_time && end_time ? (
      start_time && (
        <p>
          {UPDATE_VERSION_ACTIVITIES.includes(id) ? (
            <span>2020年5月升级版</span>
          ) : (
            <>
              <span
                className={`start_date ${
                  status == "等待开课" ? "start_date_active" : ""
                }`}
              >
                {formatDotDate(start_time)}
              </span>
              {" - "}
              <span className="end_date">{formatDotDate(end_time)}</span>
            </>
          )}
        </p>
      )
    ) : (
      ""
    );
  }
};

// 计算天数进度条百分比
const calcAlreadyStudyDayPercent = (study_package_activity) => {
  const { studied_course_day_count, course_day_count } = study_package_activity;
  const widthPercent =
    (studied_course_day_count / course_day_count) * 100 + "%";
  return widthPercent;
};

// 速度闯关练习、名校加油站、幼升小面试真题汇编、拼音课、幼升小白皮书、硬笔书法正楷、硬笔书法同步等
// 保持原有的内容展示逻辑，没有进度条显示
const hiddenProgressBar = (study_package_activity) => {
  const {
    is_speed_calc,
    is_experience,
    is_interview,
    is_parent_class,
    absentee,
    status,
    media_lessons_count,
    activity_type,
    sub_course_type,
  } = study_package_activity;
  let hiddenProgressClass;
  if (
    is_speed_calc ||
    is_experience ||
    is_interview ||
    is_parent_class ||
    absentee ||
    status == "等待开课" ||
    status == "选择开课日期" ||
    ((media_lessons_count == 0) && (sub_course_type != "reading_inclass")) ||
    activity_type == 2
  ) {
    hiddenProgressClass = "hidden_left_progress";
  }
  return hiddenProgressClass;
};

const enhanceActivities = (props) => {
  let status = "";
  // let className = "";
  props.userOwnedCourses.forEach((activity, idx) => {
    const current_time = new Date(props.study_package_system_time);
    const start_time = new Date(activity.start_time);
    const end_time = new Date(activity.end_time);
    const groupon_end_time = new Date(activity.groupon_endtime);
    if (
      activity.bought_state === "bought" ||
      activity.bought_state === "pre_paid"
    ) {
      if (activity.absentee) {
        // 囤课不显示时间
        activity.start_time = "";
        activity.end_time = "";
        if (activity.start_time_input_required) {
          // 选择开课日期
          status = "选择开课日期";
          // className = "await_class";
        } else {
          if (activity.bought_state === "pre_paid") {
            status = "等待拼团成功";
            // className = "await_class";
          } else if (activity.buy_format === "bargain") {
            status = "去练习";
          }
        }
        if (activity.expected_start_time) {
          status = `等待开课：${activity.expected_start_time}`;
          // className = "await_class";
        }
      } else if (activity.start_time_input_required) {
        // 选择开课日期
        status = "选择开课日期";
        // className = "await_class";
      } else {
        if (current_time < start_time && activity.bought_state === "bought") {
          status = `等待开课`;
          // className = "await_class";
          if (activity.absentee) {
            status = `等待开课：${activity.expected_start_time}`;
            // className = "await_class";
          }
        } else if (activity.bought_state === "pre_paid") {
          status = "等待拼团成功";
          // className = "await_class";
        } else {
          if (current_time > end_time) {
            // status = '已完课'
            status = "去上课";
          } else {
            status = "去上课";
            if (activity.absentee && activity.expected_start_time) {
              status = `等待开课：${activity.expected_start_time}`;
              // className = "await_class";
            }
            if (activity.buy_format === "bargain") {
              status = "去练习";
            }
          }
        }
      }
    } else {
      if (current_time > groupon_end_time) {
        status = "等待下期";
        // className = "await_class";
      } else {
        status = "未购买";
        // className = "await_class";
      }
    }
    const { query, url } = queryString.parseUrl(activity.buy_url);
    query.ref = "home";
    activity.buy_url = url + "?" + queryString.stringify(query);
    activity.status = status;
    // activity.className = className;
    activity.study_package_system_time = props.study_package_system_time;
    activity.free = props.free;
    activity.user = props.user;
  });
  return props.userOwnedCourses;
};

const OwnedCourses = memo(({ ...props }) => {
  console.log("OwnedCourses_props: ", props);
  const {
    userOwnedCourses,
    systemTime,
    mobileRequired,
    ownedCoursesCount,
    getMoreOwnedCoursesUrl,
  } = props;

  const free = useSelector((state) => state.free);
  const user = useSelector((state) => state.user);
  const study_package_system_time = useSelector(
    (state) => state.study_package_system_time
  );

  const [courses, setCourses] = useState(() =>
    enhanceActivities({
      userOwnedCourses,
      free,
      user,
      study_package_system_time,
    })
  );
  const [loadMoreCourse, setLoadMoreCourse] = useState(false);

  // 无课程无手机号
  const renderMobileRequiredContent = () => {
    return (
      <div className="no_activity_brief">
        <div className="no_recommend_text">
          <div className="text">
            <p>请登录购课手机号，</p>
            <p>查看已报名的课程</p>
          </div>
          <div
            className="choose_grade_button"
            onClick={() => openChooseGradeModalStatus(true)}
          >
            登录
          </div>
        </div>
        <div className="icon_age">
          <Image className="lazyload lazyloading" url={iconAge} />
        </div>
      </div>
    );
  };

  const renderCourseList = () => {
    return courses.map((study_package_activity, index) => {
      const fixAwaitCourseDate =
        study_package_activity.absentee &&
        !study_package_activity.start_time_input_required &&
        study_package_activity.bought_state != "pre_paid";

      const isThinkingCourseCloseStatus =
        study_package_activity?.is_thinking_customized &&
        new Date(study_package_activity.study_package_system_time) <
          new Date(study_package_activity.start_time);

      return (
        <div
          key={index}
          className={`owned_courses_box ${
            !loadMoreCourse && index > 2 ? "hidden" : ""
          }`}
          data-mp-qrcode-label={
            study_package_activity.mp_qrcode_url
              ? "请下载『心芽学堂』App进行学习"
              : null
          }
          data-mp-qrcode-url={
            study_package_activity.mp_qrcode_url ? qrcode : null
          }
          data-is-speed-calc={study_package_activity.is_speed_calc}
          data-url={
            study_package_activity.user
              ? `${study_package_activity.study_url}&ref=home`
              : `${study_package_activity.buy_url}&ref=home`
          }
          onClick={props.onMiniProgramClick}
        >
          <div className="left_thumbnail">
            <Image
              className="lazyload lazyloading"
              url={study_package_activity.recommend_thumb_photo_url}
            />
          </div>
          <div className="right_content_box">
            <div className="content_box">
              <div className="course_title">
                {formatDotDate(systemTime) ===
                  formatDotDate(study_package_activity.start_time) && (
                  <span>今日开课</span>
                )}
                {study_package_activity.name}
              </div>
              {study_package_activity?.calc_study_packages?.length > 0 && (
                <div className="course_desc">
                  连报：
                  {study_package_activity.calc_study_packages.map(
                    (item, index) =>
                      item.name +
                      `${
                        index ==
                        study_package_activity.calc_study_packages.length - 1
                          ? ""
                          : "、"
                      }`
                  )}
                </div>
              )}
              {
                // instance: true, 代表 “不按天排课的课程”
                study_package_activity.id != 415 && (
                  <ActivityDescription {...study_package_activity} />
                )
              }
            </div>
            <div className="course_study_progress">
              <div
                className={`left_progress ${hiddenProgressBar(
                  study_package_activity
                )}`}
              >
                <div className="progress_bar">
                  <div
                    className="progress"
                    style={{
                      width: calcAlreadyStudyDayPercent(study_package_activity),
                    }}
                  ></div>
                </div>
                <div className="text_progress">
                  {study_package_activity.sub_course_type == "reading" ||
                  study_package_activity.sub_course_type == "topic" ||
                  study_package_activity.sub_course_type == "reading_inclass"
                    ? `已学：${
                        Math.ceil(
                          (study_package_activity.studied_course_day_count /
                            study_package_activity.course_day_count) *
                            100
                        ) + "%"
                      }`
                    : `已学：${study_package_activity.studied_course_day_count}/${study_package_activity.course_day_count}次`}
                </div>
              </div>
              <div
                className={`${
                  study_package_activity.status != "去上课" ||
                  isThinkingCourseCloseStatus
                    ? "await_class"
                    : "goto_class"
                }`}
                // onClick={e => onActivityStatusClick(study_package_activity, e)}
                style={{
                  background: fixAwaitCourseDate ? "#fff" : "",
                  boxShadow: fixAwaitCourseDate
                    ? "0 0px 2px 0 rgba(0,1,0,.25)"
                    : "",
                  color: fixAwaitCourseDate ? "#333" : "",
                }}
              >
                <span
                  style={{
                    marginRight: fixAwaitCourseDate ? "0" : "",
                  }}
                >
                  {isThinkingCourseCloseStatus
                    ? "完成课前准备"
                    : study_package_activity.activity_type == 2
                    ? "选择上课模式"
                    : study_package_activity.status}
                </span>
                {fixAwaitCourseDate ? (
                  <span className="fix_await_date">修改</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderCourseTitle = () => {
    return (
      <div className="title">
        <img className="lazyload lazyloading" data-src={iconSeries} />
        我的课程
      </div>
    );
  };
  const renderMoreCourseButton = () => {
    return (
      <div className="load_more" onClick={handleMoreCourse}>
        <span>更多课程</span>
      </div>
    );
  };

  const handleMoreCourse = useCallback(async () => {
    const toast = ToastManager.showLoading("加载中...");
    const [error, res] = await awaitWrapper(request)({
      method: "get",
      url: getMoreOwnedCoursesUrl,
      headers: csrfHeaders,
    });
    toast.cancel();
    if (error) {
      const dialogOptions = {
        text: getAxiosErrorText(error, "获取更多课程"),
        mainLabel: "重试",
        subLabel: "确定",
        mainCallback: async () => {
          try {
            await handleMoreCourse();
          } catch (e) {}
        },
      };
      showDialog.call(this, dialogOptions);
      Raven.captureException(error);
    } else {
      setCourses((pre) => [
        ...pre,
        ...enhanceActivities({
          userOwnedCourses: res.data.user_activities,
          free,
          user,
          study_package_system_time,
        }),
      ]);
      setLoadMoreCourse(true);
    }
  }, [free, getMoreOwnedCoursesUrl, study_package_system_time, user]);
  return (
    <div className="owned_courses_container color_th">
      {renderCourseTitle()}
      {mobileRequired && renderMobileRequiredContent()}
      {userOwnedCourses && renderCourseList()}
      {userOwnedCourses &&
        !loadMoreCourse &&
        ownedCoursesCount > 3 &&
        renderMoreCourseButton()}
    </div>
  );
});

export default OwnedCourses;
