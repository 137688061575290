/* eslint-disable react/prop-types */
import React, { memo } from "react";
import { request, csrfHeaders } from "../../utils/request";
import { useSelector } from "../../utils/react-global-state";
import {
  LazyComp,
  LoadingHit,
  LoadingHitText,
  RetryLoadingButton,
} from "../../../async-components";
import {
  RecommendCourseModule,
  RecommendCommonActivityTabs,
} from "./RecommendCourses";
import UserAgent from "../../utils/UserAgent.js";

const SlideCarousel = LazyComp({
  loader: () =>
    import(
      /* webpackChunkName: 'slide-carousel' */ "../../../async-components/slide-carousel/SlideCarousel.jsx"
    ),
  loading: ({ error, retry }) =>
    error ? (
      <>
        <LoadingHitText style={{ marginTop: 40 }}>
          内容加载出错，请点击重新加载
        </LoadingHitText>
        <RetryLoadingButton onClick={retry}>重新加载</RetryLoadingButton>
      </>
    ) : (
      <>
        <LoadingHit style={{ marginTop: 30 }} />
        <LoadingHitText>内容加载中，请稍后...</LoadingHitText>
      </>
    ),
});

const commonFetch = async ({ url, lastUpdateTime, key }) => {
  // return new Promise((resolve, reject) =>
  //   setTimeout(() => reject(new Error("uuuuuu")), 3000)
  // );
  let isTargetCache = false;
  const isHandleCache = !UserAgent.isAndroid() && lastUpdateTime;

  if (isHandleCache) {
    if (window.localStorage) {
      const value = localStorage.getItem(`${key}${url}`);
      // console.warn("value", value, categoryGroupTimeLastUpdateTime);
      try {
        const res = JSON.parse(value);
        if (res && res.data && res.expires === lastUpdateTime) {
          isTargetCache = true;
          return res.data;
        }
      } catch (e) {
        isTargetCache = false;
        console.error(e);
      }
    }
  }

  if (url && !isTargetCache) {
    const res = await request({
      method: "GET",
      url,
      headers: csrfHeaders,
    });

    if (isHandleCache) {
      try {
        localStorage.removeItem(`${key}${url}`);
        localStorage.setItem(
          `${key}${url}`,
          JSON.stringify({
            data: res.data,
            expires: lastUpdateTime,
          })
        );
      } catch (e) {
        console.error(e);
      }
    }

    return res.data;
  }
  return {};
};

const AsyncAllCourses = ({ loaded, props }) => {
  console.log(loaded);
  const { category_groups } = loaded.categoryGroups;
  const { recommend_courses } = loaded.recommendCourses;
  const allActivityCourses = [
    ...category_groups.map((cat) => ({
      ...cat,
      category_type: cat.category_type === 0 ? "系列课" : "主题推荐",
      activities: cat.activities.map((act) => {
        const isPoetry = act.sub_course_type === "poetry";
        const { media_courses_count, media_lessons_count } = act;
        return {
          ...act,
          isPoetry,
          lessonsOrCourseCount: isPoetry
            ? media_courses_count || 0
            : media_lessons_count || 0,
          isShowLabels: !!(
            (act.suitable_grades && act.suitable_grades.length > 0) ||
            act.lessonsOrCourseCount ||
            act.course_day_count
          ),
        };
      }),
    })),
  ];

  const recommendGroups = {
    name: "个性化课程推荐",
    navigate_name: "个性化课程推荐",
    activities:
      recommend_courses && recommend_courses.length > 0
        ? recommend_courses.map((course) => {
            course.alias_type = "recommend";
            return course;
          })
        : [],
  };
  const campaigns = props.campaigns;
  return (
    <>
      <RecommendCourseModule {...props} recommendCourses={recommendGroups} />
      {campaigns && campaigns.length > 0 && (
        <SlideCarousel campaigns={campaigns} />
      )}
      <RecommendCommonActivityTabs
        {...props}
        allActivityCourses={allActivityCourses}
      />
    </>
  );
};

const LazyAsyncAllCourses = LazyComp({
  loader: {
    categoryGroups: (urls) => commonFetch(urls.categoryGroups),
    recommendCourses: (urls) => commonFetch(urls.recommendCourses),
  },
  render: (loaded, props) => <AsyncAllCourses loaded={loaded} props={props} />,
  loading: (props) => {
    console.log(props);
    return (
      <>
        {props.error ? (
          <>
            <LoadingHitText style={{ marginTop: 30 }}>
              内容加载出错，请点击重新加载
            </LoadingHitText>
            <RetryLoadingButton onClick={props.retry}>
              重新加载
            </RetryLoadingButton>
          </>
        ) : (
          <>
            <LoadingHit />
            <LoadingHitText>内容加载中，请稍后...</LoadingHitText>
          </>
        )}
      </>
    );
  },
  isUseEffect: true,
});

// 除【我的课程】外的全部课程模块
const AllCourses = memo((props) => {
  const getCategoryGroupsUrl = useSelector(
    (state) => state.getCategoryGroupsUrl
  );
  const getRecommendCoursesUrl = useSelector(
    (state) => state.getRecommendCoursesUrl
  );
  const campaigns = useSelector((state) => state.campaigns);
  const grades = useSelector((state) => state.grades);
  const categoryGroupTimeLastUpdateTime = useSelector(
    (state) => state.categoryGroupTimeLastUpdateTime
  );
  const user = useSelector((state) => state.user);
  const mobileRequired = useSelector((state) => state.mobileRequired);

  return (
    <div className="recommend_courses_container">
      <LazyAsyncAllCourses
        fetchUrl={{
          categoryGroups: {
            url: getCategoryGroupsUrl,
            lastUpdateTime: categoryGroupTimeLastUpdateTime,
            key: user?.id ?? "",
          },
          recommendCourses: { url: getRecommendCoursesUrl },
        }}
        campaigns={campaigns}
        grades={grades}
        mobileRequired={mobileRequired}
        {...props}
      />
    </div>
  );
});

export default AllCourses;
