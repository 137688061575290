import raven from 'raven-js'
import { isDevelopment } from '../components/utils/Env'
const gatherPerformanceInfo = () => {
  const timingData = {}
  const performance = window.performance
  const navigation = performance.navigation
  const timing = performance.timing
  const memory = performance.memory
  /**
   * type 返回值应该是0,1,2 中的一个。分别对应三个枚举值:
   * 0 : TYPE_NAVIGATE (用户通过常规导航方式访问页面，比如点一个链接，或者一般的get方式)
   * 1 : TYPE_RELOAD (用户通过刷新，包括JS调用刷新接口等方式访问页面)
   * 2 : TYPE_BACK_FORWARD (用户通过后退按钮访问本页面)
   */
  // 进入页面的方式
  if (navigation) {
    const type = navigation.type
    timingData.nav_type = type
  }

  const redirect = (timing.redirectEnd ?? 0) - (timing.redirectStart ?? 0)
  timingData.redirect = redirect

  // DNS 解析耗时：
  const dns = (timing.domainLookupEnd ?? 0) - (timing.domainLookupStart ?? 0)
  timingData.dns = dns
  // TCP 链接耗时：
  const tcp = (timing.connectEnd ?? 0) - (timing.connectStart ?? 0)
  timingData.tcp = tcp
  // TTI
  const tti = (timing.domInteractive ?? 0) - (timing.navigationStart ?? 0)
  timingData.tti = tti
  // TTFB
  const ttfb = (timing.responseStart ?? 0) - (timing.requestStart ?? 0)
  timingData.ttfb = ttfb
  // 总体网络交互耗时，即开始跳转到服务器资源下载完成：
  const network = (timing.responseEnd ?? 0) - (timing.navigationStart ?? 0)
  timingData.network = network
  // 解析dom：
  const processing =
    ((timing.domContentLoadedEventEnd || timing.domLoading) ?? 0) -
    (timing.domLoading ?? 0)
  timingData.dom_p = processing
  // APP CACHE 耗时：
  const app_cache = Math.max(
    (timing.domainLookupStart ?? 0) - (timing.fetchStart ?? 0),
    0
  )
  timingData.app_cache = app_cache
  // 总耗时：
  const total =
    ((timing.loadEventEnd ||
      timing.loadEventStart ||
      timing.domComplete ||
      timing.domLoading) ??
      0) - (timing.navigationStart ?? 0)
  timingData.total = total

  // 白屏时间
  const blank_page = (timing.domLoading ?? 0) - (timing.navigationStart ?? 0)
  timingData.blank_page = blank_page

  // 文档下载时间
  const page_download = timing.responseEnd - timing.responseStart
  timingData.page_download = page_download

  if (window.performance.getEntries) {
    window.performance.getEntries().forEach((entry) => {
      if (entry.name === 'first-paint') {
        timingData.fp = entry.startTime.toFixed(2) * 1
      }

      if (entry.name === 'first-contentful-paint') {
        timingData.fcp = entry.startTime.toFixed(2) * 1
      }
    })
  }

  timingData.name = encodeURIComponent(window.location.href.replace(/#.*/, ''))

  if (memory) {
    timingData.m_limit = memory.jsHeapSizeLimit
    timingData.m_total = memory.totalJSHeapSize
    timingData.m_used = memory.usedJSHeapSize
  }

  const userId = document
    .getElementById('web-root_container')
    ?.getAttribute?.('data-user-id')
  timingData.user_id = userId ? userId * 1 : ''
  return timingData
}

const imgReport = (url) => {
  console.log('imgReport')
  return new Promise((resolve, reject) => {
    const image = new Image()
    // image.setAttribute("crossOrigin", "Anonymous");
    // let items = [];
    // items = JSON.Parse(data);
    // console.log("items", items);
    image.onload = () => {
      console.log('imgReport loaded')
      resolve()
    }
    image.onerror = (e) => {
      reject(e)
      console.log(e.message)
      console.log(e)
    }

    image.src = url
  })
}

const xmlPostData = (url, data) => {
  console.log('xmlPostData', data)
  return new Promise((resolve, reject) => {
    var client = new XMLHttpRequest()
    client.onreadystatechange = function () {
      if (client.readyState === 4) {
        if (
          (client.status >= 200 && client.status < 300) ||
          client.status === 304
        ) {
          resolve()
        } else {
          reject(new Error(`${client.status} ${client.statusText}`))
        }
      }
    }
    client.open('POST', url, false)
    client.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
    client.setRequestHeader(
      'Access-Control-Allow-Origin',
      'http://localhost:3000, https://xinya.me'
    )
    client.send(JSON.stringify(data))
  })
}

const convertToStr = (obj) => {
  return Object.keys(obj).reduce((str, k) => {
    const cur = `${k}=${obj[k]}`
    if (str) {
      return `${cur}&${str}`
    } else {
      return cur
    }
  }, '')
}

const postAnalysisData = async (url, data) => {
  const urlStr = url + (url.indexOf('?') < 0 ? '?' : '&') + convertToStr(data)

  console.log('urlLength', urlStr)
  if (urlStr.length < 2083) {
    await imgReport(urlStr)
  } else {
    // attention: server not support cors yet
    // data.xhr = true;
    // await xmlPostData(url, data);
  }
}

const uploadInfo = async (info) => {
  console.log('timingData', info)
  await postAnalysisData('https://t.xinya.me/log_performance', info)
}

const performanceInfo = () => {
  let logged = false
  window.addEventListener('load', () => {
    console.log('performanceInfo addEventListener')

    const info = gatherPerformanceInfo()
    logged = 'ing'
    // info.unload = 0;
    uploadInfo(info)
      .then(() => {
        logged = true
      })
      .catch((e) => {
        logged = false
        console.error(e.message)
        // raven.captureException(JSON.stringify(e))
      })
  })
  window.addEventListener('unload', () => {
    if (!logged) {
      const info = gatherPerformanceInfo()

      uploadInfo(info)
        .then(() => {
          logged = true
        })
        .catch((e) => {
          console.error(e)
          raven.captureException(JSON.stringify(e))
        })
    }
  })
}

if (!isDevelopment()) {
  performanceInfo()
}
