import React from "react";
import "./PrivacyDialog.scss";

const PrivacyDialog = () => {
  return (
    <div className="comp_privacy_dialog">
      <p style={{ fontWeight: 700, fontSize: 18 }}>用户隐私政策</p>
      <ol>
        <li className="privacy_des">
          1.
          您在使用我们的产品或服务时，将会提供与具体功能相关的个人信息(可能涉及帐号、设备等信息)。
        </li>
        <li className="privacy_des">
          2.
          在未征得您事先许可的情况下，心芽学堂不会将这些信息对外披露或向第三方提供。
        </li>
        <li className="privacy_des">
          3.
          心芽学堂尊重并保护所有使用服务用户的个人隐私权，请您在使用前务必仔细阅读并透彻理解
          <a href="/privacy">《用户隐私政策》</a>与
          <a href="/agreement">《用户服务协议》</a>。
        </li>
      </ol>
    </div>
  );
};

export default PrivacyDialog;
