import ReactDOM from "react-dom";

export default class ComponentProxy {
  open() {
    if (!this.node) {
      this.node = document.createElement("div");
      document.body.appendChild(this.node);
    }
    ReactDOM.render(this.createComponent(), this.node);

    // 如果海报不走“动画退出效果”, 则移除绝对定位相关样式
    // FIXME sometimes this makes clicking close button not working.
    // const img_realetive = document.getElementById("_img_realetive");
    // const img_transition = document.getElementById("_img_transition");
    // if (!img_transition && img_realetive) {
    //   img_realetive.children[0].style.cssText =
    //     "position: static; margin-left: 0";
    // }
  }

  createComponent() {
    throw new Error("Child class must implement this method!");
  }

  cancel() {
    // ReactDOM.unmountComponentAtNode(this.node);
    // this.node = null;

    const comp_share_poster = document.getElementById("comp-share-poster");
    const img_realetive = document.getElementById("_img_realetive");
    const text_container = document.getElementById("_text_container");
    const reward_revent_cheat = document.getElementById("reward_revent_cheat");
    const img_transition = document.getElementById("_img_transition");
    const btn_close = document.getElementById("_btn_close");

    /**
     * 开关: img_transition 存在
     * 执行海报“动画退出效果”
     */
    if (img_transition) {
      img_realetive.style.cssText = "position: static";
      text_container.style.cssText = "visibility: hidden;";
      reward_revent_cheat.style.cssText = "visibility: hidden;";
      btn_close.style.cssText = "visibility: hidden;";
      img_transition.style.cssText = `
        transition: all 0.5s ease-out;
        width: 0;
        margin-left: 30%;
        bottom: 25px;
        opacity: 0;        
        transform: skew(20deg, 20deg);
      `;

      comp_share_poster.style.cssText = `
        transition: all 0.5s ease-out;
        background: rgba(0,0,0,0);
      `;

      this.nodeTimeout = setTimeout(() => {
        ReactDOM.unmountComponentAtNode(this.node);
        this.node = null;
        clearTimeout(this.nodeTimeout);
      }, 500);
      return;
    }

    ReactDOM.unmountComponentAtNode(this.node);
    this.node = null;
  }
}
